import React from "react";
import ReactDOM from "react-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { keycloak } from "./keycloak";

import "./i18n";

import "./index.css";

const initOptions = {
    onLoad: "login-required",
};

ReactDOM.render(
    <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ReactKeycloakProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
