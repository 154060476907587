import React from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import NProgressWrapper from "@components/common/NProgressWrapper";

import "./Login.css";

export default function Login() {
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();

    const [loading, setLoading] = React.useState<boolean>(false);
    React.useState<boolean>(false);

    async function handleValidSubmit(email: string) {
        await fetch(`${process.env.REACT_APP_ADMIN_ACCESS}/customerAccount`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                authorization: `Bearer ${keycloak.token}`,
            },
            body: `email=${email.toLowerCase()}`,
            method: "POST",
        })
            .then((result) => result.json())
            .then((response: any) => {
                if (!response.error) {
                    //vérif status account
                    if (response.data.etataccount === "Activer") {
                        localStorage.setItem(
                            "Uid_Entitie_User",
                            response.data.uidentitie
                        );
                        localStorage.setItem(
                            "firstName",
                            response.data.firstname
                        );
                        localStorage.setItem("lastName", response.data.name);
                        localStorage.setItem(
                            "uuidprofile",
                            response.data.uuidprofile
                        );
                        localStorage.setItem("email", response.data.email);
                        localStorage.setItem(
                            "secondaryColor",
                            response.data.secondarycolor
                        );
                        localStorage.setItem(
                            "mainColor",
                            response.data.maincolor
                        );
                        setLoading(false);
                        navigate("/Gestioncomptes/Comptesclients");
                    }
                }
                setLoading(false);
            })
            .catch((_) => {
                setLoading(true);
                return false;
            });
    }

    React.useEffect(() => {
        const uidEntity = localStorage.getItem("Uid_Entitie_User");

        if (uidEntity !== null && uidEntity === keycloak.tokenParsed?.uuid) {
            navigate("/home");
        } else if (keycloak.authenticated) {
            handleValidSubmit(keycloak.tokenParsed?.email);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Container fluid={true} className="p-0 m-0 overflow-hidden">
                <NProgressWrapper isLoading={loading} />
            </Container>
        </React.Fragment>
    );
}
